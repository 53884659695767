/** @jsx jsx */
import { css } from '@emotion/core';
import { enFont01, jpFont01, mq } from "../constants/styleConstants";

export const notFoundStyle = {
  block: css`
    max-width: 80rem;
    margin: 8rem auto 4rem;
    padding: 0 1.25rem;
    text-align: center;
    h1 {
      font-size: 2rem;
      ${enFont01.bold};
      margin-bottom: 1rem;
      letter-spacing: 0.05em;
    }
    p {
      ${jpFont01.medium};
      font-size: 1rem;
      letter-spacing: 0.1em;
    }
    ${mq('medium')} {
      margin-top: 12rem;
      margin-bottom: 8rem;
    }
  `,
}
