import React, { useCallback, useContext, useEffect, useRef } from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import { TransitionPortal } from "gatsby-plugin-transition-link";
import TransitionCover from "../components/TransitionCover/transitionCover";
import GlobalContext from "../context/globalContext";
import { gsap } from "gsap";
import { duration, entryDelay } from "../constants/componentConstants";
import { notFoundStyle } from "../components/notFoundStyle";

const NotFoundPage = () => {
  const global = useContext(GlobalContext);
  useEffect(() => {
    global.setHideHeaderLogo(false);
    global.setEntryAnimation(entryAnimation);
    global.setExitAnimation(exitAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const commonLayer = useRef(null);
  const entryAnimation = useCallback(() => {
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 100,
      })
      .to(commonLayer.current, {
        xPercent: 200,
        ease: "power2.in",
        duration: duration,
        delay: entryDelay,
        onComplete: () => {
          commonLayer.current.style.display = "none";
        }
      })
  }, []);
  const exitAnimation = useCallback(({ node }) => {
    commonLayer.current.style.display = "block";
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 0,
      })
      .to(commonLayer.current, {
        xPercent: 100,
        ease: "power2.out",
        duration: duration,
      })
      .to(node, {
        opacity: 0,
        duration: 0,
      })
  }, []);

  return (
    <>
      <SEO title="Page Not found" />

      <div css={notFoundStyle.block}>
        <h1>Page Not Found</h1>
        <p>ページが見つかりませんでした</p>
      </div>

      <TransitionPortal>
        <TransitionCover ref={commonLayer} />
      </TransitionPortal>
    </>
  )
}

export default NotFoundPage
